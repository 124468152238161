import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styles from './NewsPage.module.css'
import Pagination from './Pagination/Pagination'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const NewsPage = () => {
  const [posts, setPosts] = useState([])
  const [filteredPosts, setFilteredPosts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(6) // Кількість постів на сторінку

  const accessToken =
    'EAAHXKkqkZAgEBO2MAeYeWhSu1t4vOuKlrZAr5c94IqkzsGB7ccOjWZAC8YsU89ahfVSHyke1n5AZCmWjvhS2QWVsKCPMtbVhw7ZAuLtHwfnuIViuMjJk4u6XrOB0OphR9Y5bESmSGE4P2FmCB63bMnFW1kZBY8lZAl9a2CWu2w5CZArynhmKoZCOi8XipKYyZCVZBNjtn0UCQ2RApwxc30ZD'
  const userId = '113209753721571'

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        let postsArray = []
        let nextPageUrl = `https://graph.facebook.com/v20.0/${userId}/posts?fields=message,full_picture,from,permalink_url,created_time&access_token=${accessToken}`

        while (nextPageUrl) {
          const response = await axios.get(nextPageUrl)
          postsArray = [...postsArray, ...response.data.data]
          nextPageUrl = response.data.paging?.next || null // Якщо є наступна сторінка
        }

        setPosts(postsArray)
      } catch (error) {
        console.error('Error fetching posts:', error)
      }
    }

    fetchPosts()

    // Встановлення кількості постів на сторінку залежно від ширини вікна
    const handleResize = () => {
      if (window.innerWidth <= 720) {
        setPostsPerPage(3) // Менше 720px, відображаємо 3 пости на сторінку
      } else {
        setPostsPerPage(6) // Більше 720px, відображаємо 6 постів на сторінку
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Викликаємо одразу, щоб перевірити поточну ширину

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [accessToken, userId])

  // Фільтрація за хештегом #important
  useEffect(() => {
    const filterByHashtag = () => {
      const filtered = posts.filter(
        (post) => post.message && post.message.includes('#important'),
      )
      setFilteredPosts(filtered)
    }

    filterByHashtag()
  }, [posts])

  // Розрахунок поточних постів
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost)
  const paginate = (pageNumber) => setCurrentPage(pageNumber)
  const { t } = useTranslation()

  return (
    <div>
      <div className={styles.posts}>
        <div className={`${'customContainer'} ${styles.text}`}>
          <h2 className={styles.title}>{t('news.title')}</h2>
          <p className={styles.desc}>{t('news.desc')}</p>
        </div>
        <div className={`${'customContainer'} ${styles.postsGrid} `}>
          {currentPosts.map((post) => (
            <Link
              to={post.permalink_url}
              target={'_blank'}
              key={post.id}
              className={styles.postItem}
            >
              {/* Картинка посту */}
              {post.full_picture && (
                <img
                  src={post.full_picture}
                  alt="Post"
                  className={styles.postImage}
                />
              )}

              {/* Ім'я публікатора та дата */}
              <p className={styles.postAuthor}>
                {new Date(post.created_time).toLocaleDateString()}
              </p>

              {/* Опис (обмеження до 2 рядків) */}
              <p className={styles.postMessage}>
                {post.message || 'Без опису'}
              </p>
            </Link>
          ))}
        </div>

        {/* Пагінація */}
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={filteredPosts.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </div>
  )
}

export default NewsPage
